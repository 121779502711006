import PropTypes from 'prop-types';
import UserShape from './UserShape';
import FoodtruckCategoryShape from './FoodtruckCategoryShape';
import AttachmentShape from './AttachmentShape';
import EventShape from './EventShape';
import FoodtruckReviewShape from './FoodtruckReviewShape';

const FoodtruckShape = PropTypes.shape({
  id: PropTypes.string,
  user: UserShape || PropTypes.object,
  visible: PropTypes.bool,
  active: PropTypes.bool,
  name: PropTypes.string,
  slug: PropTypes.string,
  description: PropTypes.string,
  activesince: PropTypes.string,
  products: PropTypes.string,
  owners: PropTypes.string,
  facebookpagename: PropTypes.string,
  twittername: PropTypes.string,
  instagramname: PropTypes.string,
  website: PropTypes.string,
  email: PropTypes.string,
  newsletter: PropTypes.bool,
  address: PropTypes.string,
  postalcode: PropTypes.string,
  city: PropTypes.string,
  phonenumber: PropTypes.string,
  ratingtotal: PropTypes.number,
  numberofratings: PropTypes.number,
  categories: PropTypes.arrayOf(FoodtruckCategoryShape || PropTypes.object),
  truckimages: PropTypes.arrayOf(AttachmentShape || PropTypes.object),
  foodimages: PropTypes.arrayOf(AttachmentShape || PropTypes.object),
  headerimage: AttachmentShape || PropTypes.object,
  events: PropTypes.arrayOf(EventShape || PropTypes.object),
  reviews: PropTypes.arrayOf(FoodtruckReviewShape || PropTypes.object),
  userreview: FoodtruckReviewShape || PropTypes.object,
});
export default FoodtruckShape;
