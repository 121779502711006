import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { FaSearch } from 'react-icons/fa';
import s from './SearchField.css';

class SearchField extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    className: PropTypes.string,
    initialValue: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    placeholder: 'Zoek trucks of festivals',
    className: null,
    initialValue: '',
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      value: props.initialValue || '',
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.onSubmit(this.state.value);
  };

  handleChange = (e) => {
    const { value } = e.currentTarget;
    this.setState(state => ({
      ...state,
      value,
    }));
  };

  render() {
    const { placeholder, className } = this.props;
    const { value } = this.state;

    return (
      <form className={cx(s.container, className)} onSubmit={this.handleSubmit}>
        <input type="search" value={value} className={s.field} placeholder={placeholder} onChange={this.handleChange} />
        <FaSearch size={25} className={s.icon} onClick={this.handleSubmit} />
      </form>
    );
  }
}

export default withStyles(s)(SearchField);
