import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ScreenReaderOnly.css';

class ScreenReaderOnly extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const { children } = this.props;

    return <span className={s.sronly}>{children}</span>;
  }
}

export default withStyles(s)(ScreenReaderOnly);
