import fetch from './fetch';
import errors from './errors';

const matchDateTime = /(date|datetime|At)$/;

const graphqlclient = async function graphqlclient(query, variables, req) {
  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.info('graphql query: ', query);
  }

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (!process.env.BROWSER) {
    if (req && req.cookies.id_token) {
      headers.Cookie = `id_token=${req.cookies.id_token}`;
    }
  }

  const resp = await fetch('/graphql', {
    method: 'post',
    headers,
    body: JSON.stringify({ query, variables }),
    credentials: 'include',
  });

  if (resp.status !== 200) {
    const errorText = await resp.text();
    const error = new Error(`GraphQL error ${resp.status} (${resp.statusText}): ${errorText}`);
    errors.captureException(error, {
      extra: { query },
    });
    throw error;
  }

  // Convert datetimes.
  const result = JSON.parse(await resp.text(), (key, value) => {
    if (matchDateTime.test(key) && typeof value === 'string') {
      const date = new Date(value);
      if (!Number.isNaN(date.getTime())) {
        return date;
      }
    }
    return value;
  });

  if (result.errors && result.errors.length >= 0) {
    // Report errors to the console server side or development console.
    if (!process.env.BROWSER || __DEV__) {
      // eslint-disable-next-line no-console
      result.errors.forEach(err => console.error(err));
    }

    errors.captureMessage(`Errors in GraphQL query: ${JSON.stringify(result.errors, null, 2)}`, {
      extra: { query },
    });

    throw new Error('Errors during internal request');
  }

  // Return data.
  return result.data;
};

export default graphqlclient;
