import format from 'date-fns/format';
import locale from 'date-fns/locale/nl';

export const shortDateFormat = 'D MMMM';
export const shortDateMonthFormat = 'D MMM';
export const shortDateTimeFormat = 'D MMMM YYYY, HH:mm';
export const iso8601DateTime = 'YYYY-MM-DDTHH:mm:ss';
const dateFormatOptions = { locale };

export function formatDate(date, dateFormat) {
  return format(date, dateFormat || shortDateFormat, dateFormatOptions);
}

export function formatDateTime(date, dateFormat) {
  return format(date, dateFormat || shortDateTimeFormat, dateFormatOptions);
}

export function formatIso8601DateTime(date) {
  return format(date, iso8601DateTime);
}

export function formatPeriod(startDate, endDate, currentYear) {
  const year = endDate.getFullYear();
  const yearSuffix = year !== currentYear ? ` ${year}` : '';
  if (startDate.getMonth() !== endDate.getMonth()) {
    return `${formatDate(startDate, shortDateMonthFormat)} t/m ${formatDate(endDate, shortDateFormat)}${yearSuffix}`;
  }
  if (startDate.getDate() !== endDate.getDate()) {
    return `${startDate.getDate()} t/m ${formatDate(endDate, shortDateFormat)}${yearSuffix}`;
  }
  return formatDate(startDate) + yearSuffix;
}
