import queryString from 'query-string';
import history from '../../history';

export const defaultSort = 'date';

export const sorts = {
  date: {
    title: 'Datum',
    field: 'startdatetime',
    direction: 'asc',
  },
  name: {
    title: 'Naam',
    field: 'name',
    direction: 'asc',
  },
  new: {
    title: 'Nieuwste',
    field: 'id',
    direction: 'desc',
  },
  distance: {
    title: 'Afstand',
    field: 'distance',
    direction: 'asc',
  },
};

export function gotoUrl(search, sort, latitude, longitude) {
  let newUrl = '/festivals';

  const query = {};

  if (search) {
    query.search = search;
  }
  if (sort && defaultSort !== sort) {
    query.sort = sort;
  }
  if (latitude && longitude) {
    delete query.sort;
    query.lat = (`${latitude}`).substr(0, 6);
    query.lon = (`${longitude}`).substr(0, 6);
  }

  newUrl = `${newUrl}?${queryString.stringify(query)}`.replace(/\?$/, '');

  history.push(newUrl);
}
