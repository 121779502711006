import React from 'react';
import Home from './Home';
import graphqlclient from '../../core/graphqlclient';
import Layout from '../../components/Layout';

async function action({ data, req, query }) {
  let {
    posts,
    events,
    foodtrucks,
    user,
  } = data;
  const { search } = query;

  if (!posts) {
    const vars = {
      search,
    };
    const newdata = await graphqlclient(`
        query Home($search: String) {
          post(limit: 7 search: $search) { id, slug, title, introduction, author { id, slug, displayname, profileimage { id, url } }, authornameoverride, categories { name }, images(limit: 1), createdAt }
          event(limit: 5 future: true search: $search) { id, slug, typeslug, name, startdatetime, enddatetime, city, logoimage { url } }
          foodtruck(limit: 26 orderby: updatedAt direction: desc active: true search: $search) { slug, name, categories { name }, headerimage { url } }
          me { displayname }
        }
      `, vars, req);

    posts = newdata.post;
    events = newdata.event;
    foodtrucks = newdata.foodtruck;
    user = newdata.me;
  }

  const viewdata = { posts, events, foodtrucks, user, search };
  return {
    title: 'De Foodtruck community van Nederland',
    chunks: ['home'],
    component: <Layout user={user} hideMenu noMainSection searchValue={search}><Home {...viewdata} /></Layout>,
    data: viewdata,
    description: 'FoodtruckSpotters.nl - Alle foodtrucks en foodtruck festivals van Nederland! Reviews, agenda en meer!',
  };
}

export default action;
