import PropTypes from 'prop-types';

const AttachmentShape = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  copyright: PropTypes.string,
  url: PropTypes.string,
  mimetype: PropTypes.string,
  filesize: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
});
export default AttachmentShape;
