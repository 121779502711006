import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import s from './EventListItem.css';
import EventShape from '../../data/shapes/EventShape';
import Link from '../Link/Link';
import { formatPeriod } from '../../core/dateUtils';
import { getImageWithSize } from '../../core/images';

function calcIsNow(event, now) {
  return event.startdatetime <= now && event.enddatetime >= now;
}

class EventListItem extends React.Component {
  static propTypes = {
    event: EventShape.isRequired,
    scrollPosition: PropTypes.exact({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
  };

  render() {
    const { event, scrollPosition } = this.props;

    const now = new Date();
    const isNow = calcIsNow(event, now);
    const { name, city } = event;
    const url = `/${event.typeslug}/${event.slug}`;
    const linkTitle = `Meer informatie over ${event.name}`;

    return (
      <div className={s.item}>
        <Link to={url} title={linkTitle} className={s.imageLink}>
          <LazyLoadImage
            src={getImageWithSize(event.logoimage, 400, 400)}
            width="400"
            height="400"
            alt={name}
            title={name}
            className={s.image}
            scrollPosition={scrollPosition}
          />
        </Link>
        <Link to={url} title={linkTitle} className={s.titleLink}>
          <span className={s.name}>{name}</span><br />
          <span className={s.city}>{city}</span>
          <span className={s.date}>
            {isNow ? 'Nu: ' : ''}
            {formatPeriod(
              event.startdatetime,
              event.enddatetime,
              now.getFullYear(),
            )}
          </span><br />
        </Link>
      </div>
    );
  }
}

export default withStyles(s)(EventListItem);
