import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { TiLocationOutline } from 'react-icons/ti';
import cx from 'classnames';
import s from './LocatorStar.css';
import Star from './Star';
import { isGeolocationAvailable } from '../../core/browserUtils';

class LocatorStar extends React.Component {
  static propTypes = {
    onResolveLocation: PropTypes.func.isRequired,
    className: PropTypes.string,
  };

  state = {
    hasLocator: false,
  };

  componentDidMount = () => {
    isGeolocationAvailable().then((hasLocator) => {
      this.setState(() => ({
        hasLocator,
      }));
    });
  };

  handleLocatorClick = () => {
    navigator.geolocation.getCurrentPosition(this.props.onResolveLocation, () => {
      this.setState(() => ({
        hasLocator: false,
      }));
      this.props.onResolveLocation(null);
    });
  };

  render() {
    if (!this.state.hasLocator) {
      return null;
    }

    return (
      <Star
        className={cx(this.props.className, s.locator)}
        onClick={this.handleLocatorClick}
        icon={<TiLocationOutline size={40}/>}
      >
        toon in de buurt
      </Star>
    );
  }
}

export default withStyles(s)(LocatorStar);
