import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import cx from 'classnames';
import s from './FoodtruckListItem.css';
import Link from '../Link/Link';
import FoodtruckShape from '../../data/shapes/FoodtruckShape';
import { getImageWithSize } from '../../core/images';

class FoodtruckListItem extends React.Component {
  static propTypes = {
    foodtruck: FoodtruckShape.isRequired,
    className: PropTypes.string,
    scrollPosition: PropTypes.exact({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
  };

  render() {
    const { foodtruck, className, scrollPosition } = this.props;

    const url = `/foodtruck/${foodtruck.slug}`;
    const linkTitle = `Meer informatie over ${foodtruck.name}`;

    return (
      <div className={cx(s.item, className)}>
        <Link to={url} title={linkTitle} className={s.imageLink}>
          <LazyLoadImage
            src={getImageWithSize(foodtruck.headerimage, 200, 200)}
            width="200"
            height="200"
            alt={foodtruck.name}
            title={foodtruck.name}
            className={s.image}
            scrollPosition={scrollPosition}
          />
        </Link>
        <Link to={url} title={linkTitle} className={s.titleLink}>{foodtruck.name}</Link><br />
        <small className={s.categories}>{foodtruck.categories.map(c => c.name).join(' / ')}</small>
      </div>
    );
  }
}

export default withStyles(s)(FoodtruckListItem);
