/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Menu from 'react-burger-menu/lib/menus/slide';
import cx from 'classnames';
import sn from './Navigation.css';
import s from './PhoneNavigation.css';
import Link from '../Link';
import logo from '../../content/logo.svg';
import UserShape from '../../data/shapes/UserShape';

class PhoneNavigation extends React.Component {
  static propTypes = {
    user: UserShape,
  };

  state = {
    isOpen: false,
  };

  handleStateChange = ({ isOpen }) => {
    this.setState(() => ({
      isOpen,
    }));
  };

  handleClick = () => {
    this.setState(() => ({
      isOpen: false,
    }));
  };

  render() {
    const { user } = this.props;

    return (
      <div className={s.navbarcontainer}>
        <Menu right onStateChange={this.handleStateChange} isOpen={this.state.isOpen}>
          <Link to="/foodtrucks" className={cx(s.item, sn.menuFoodtrucks)} onClick={this.handleClick}>Spot deze trucks</Link>
          <Link to="/festivals" className={cx(s.item, sn.menuEvents)} onClick={this.handleClick}>Festival agenda</Link>
          <Link to="/" className={cx(s.item, sn.menuNews)} onClick={this.handleClick}>Foodtruck Nieuws</Link>

          {user && (
            <>
              <Link to="/profiel" className={s.item} onClick={this.handleClick}>Profiel</Link>
              <span className={s.item}> of </span>
              <a href="/logout" className={s.item} onClick={this.handleClick}>Uitloggen</a>
            </>)}
          {!user && (
            <>
              <Link to="/login" className={s.item} onClick={this.handleClick}>Inloggen</Link>
              <span className={s.item}> of </span>
              <Link to="/aanmelden" className={s.item} onClick={this.handleClick}>Aanmelden</Link>
            </>)}
        </Menu>
        <div className={s.navbar}>
          <Link to="/"><img className={s.logo} alt="FoodtruckSpotters" title="FoodtruckSpotters" src={logo} width="100" height="100" /></Link>
          <Link to="/" className={s.logoText}>Foodtruck<br />Spotters</Link>
        </div>
      </div>
    );
  }
}

export default withStyles(s, sn)(PhoneNavigation);
