import PropTypes from 'prop-types';
import FoodtruckShape from './FoodtruckShape';
import AttachmentShape from './AttachmentShape';

const EventShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  slug: PropTypes.string,
  typeslug: PropTypes.string,
  description: PropTypes.string,
  openingtimes: PropTypes.string,
  locationname: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  provincecode: PropTypes.string,
  countrycode: PropTypes.string,
  geolocation: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  startdatetime: PropTypes.instanceOf(Date),
  enddatetime: PropTypes.instanceOf(Date),
  facebookpagename: PropTypes.string,
  twittername: PropTypes.string,
  instagramname: PropTypes.string,
  website: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  foodtrucks: PropTypes.arrayOf(FoodtruckShape || PropTypes.object),
  numberOfFoodtrucks: PropTypes.number,
  logoimage: AttachmentShape || PropTypes.object,
  headerimage: AttachmentShape || PropTypes.object,
});
export default EventShape;
