import PropTypes from 'prop-types';
import UserShape from './UserShape';
import FoodtruckShape from './FoodtruckShape';

const FoodtruckReviewShape = PropTypes.shape({
  id: PropTypes.string,
  user: UserShape || PropTypes.object,
  foodtruck: FoodtruckShape || PropTypes.object,
  ratingfood: PropTypes.number,
  ratingtruck: PropTypes.number,
  ratingservice: PropTypes.number,
  ratingtotal: PropTypes.number,
  content: PropTypes.string,
  visitdate: PropTypes.instanceOf(Date),
  createdAt: PropTypes.instanceOf(Date),
});
export default FoodtruckReviewShape;
