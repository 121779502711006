import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import s from './Footer.css';
import Link from '../Link';
import ScreenReaderOnly from '../Utility/ScreenReaderOnly';
import logo from '../../content/logo.svg';

class Footer extends React.Component {
  render() {
    return (
      <div className={s.root} itemScope itemType="http://schema.org/Organization">
        <link itemProp="url" href="https://www.foodtruckspotters.nl/" />
        <div className={cx(s.content, s.separator)} />
        <div className={s.pullout}>
          <div className={s.content}>
            <p>
              <span>© <span itemProp="name">FoodtruckSpotters</span></span>
              <span> • </span>
              <span>Ontbreken gegevens of kan er iets beter?</span> <Link to="/contact">Neem contact met ons op!</Link>
            </p>
            <nav>
              <Link to="/over">Over ons</Link>
              <span> • </span>
              <Link to="/privacy">Privacybeleid</Link>
              <span> • </span>
              <Link to="/voordelen-voor-foodtrucks">Informatie voor foodtrucks</Link>
              <span> • </span>
              <Link to="/contact">Contact</Link>
            </nav>
          </div>
        </div>
        <div className={cx(s.content, s.logos)}>
          <p className={s.socialLogos}>
            <a href="https://www.facebook.com/foodtruckspotters" title="Bezoek FoodtruckSpotters op Facebook" itemProp="sameAs" className={s.socialIcon}>
              <FaFacebook size={30} /><ScreenReaderOnly>Facebook</ScreenReaderOnly>
            </a>
            <a href="https://www.instagram.com/foodtruckspotters" title="Volg FoodtruckSpotters op Instagram" itemProp="sameAs" className={s.socialIcon}>
              <FaInstagram size={30} /><ScreenReaderOnly>Instagram</ScreenReaderOnly>
            </a>
            <a href="https://www.twitter.com/foodtruckspttrs" title="Volg FoodtruckSpotters op Twitter" itemProp="sameAs" className={s.socialIcon}>
              <FaTwitter size={30} /><ScreenReaderOnly>Twitter</ScreenReaderOnly>
            </a>
          </p>
          <img className={s.logo} alt="FoodtruckSpotters" title="FoodtruckSpotters" src={logo} width="100" height="100" />
          <div className={s.logoText}>Foodtruck<br />Spotters</div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(Footer);
