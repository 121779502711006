import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import s from './FoodtruckList.css';
import FoodtruckShape from '../../data/shapes/FoodtruckShape';
import FoodtruckListItem from './FoodtruckListItem';

class FoodtruckList extends React.Component {
  static propTypes = {
    foodtrucks: PropTypes.arrayOf(FoodtruckShape).isRequired,
    fullWidth: PropTypes.bool,
    scrollPosition: PropTypes.exact({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
  };

  static defaultProps = {
    fullWidth: false,
  };

  render() {
    const { foodtrucks, fullWidth, scrollPosition } = this.props;

    if (!foodtrucks || foodtrucks.length === 0) {
      return null;
    }

    return (
      <ul className={cx(s.list, fullWidth ? s.responsive : null)}>
        {foodtrucks.map(foodtruck => (
          <li key={foodtruck.slug} className={s.item}>
            <FoodtruckListItem
              foodtruck={foodtruck}
              className={s.innerItem}
              scrollPosition={scrollPosition}
            />
          </li>
        ))}
      </ul>
    );
  }
}

export default withStyles(s)(trackWindowScroll(FoodtruckList));
