import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Home.css';
import { PostShape, EventShape, FoodtruckShape } from '../../data/shapes';
import PagedPostList from '../../components/PostList/PagedPostList';
import FoodtruckList from '../../components/FoodtruckList/FoodtruckList';
import EventList from '../../components/EventList';
import LocatorStar from '../../components/Star/LocatorStar';
import MoreButton from '../../components/Button/MoreButton';
import { gotoUrl as gotoFoodtruckUrl } from '../foodtrucks/common';
import { gotoUrl as gotoEventsUrl } from '../events/common';
import MainSection from '../../components/Layout/MainSection';
import Link from '../../components/Link';

class Home extends React.Component {
  static propTypes = {
    posts: PropTypes.arrayOf(PostShape).isRequired,
    events: PropTypes.arrayOf(EventShape).isRequired,
    foodtrucks: PropTypes.arrayOf(FoodtruckShape).isRequired,
    search: PropTypes.string,
  };

  handleTruckLocatorClick = (location) => {
    if (!location) {
      gotoFoodtruckUrl();
    } else {
      gotoFoodtruckUrl({
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
      });
    }
  };

  handleEventLocatorClick = (location) => {
    if (!location) {
      gotoEventsUrl();
    } else {
      gotoEventsUrl(null, null, location.coords.latitude, location.coords.longitude);
    }
  };

  render() {
    const {
      posts,
      events,
      foodtrucks,
      search,
    } = this.props;

    let festivalsLink = '/festivals';
    let foodtrucksLink = '/foodtrucks';

    if (search) {
      festivalsLink = `${festivalsLink}?search=${encodeURIComponent(search)}`;
      foodtrucksLink = `${foodtrucksLink}?search=${encodeURIComponent(search)}`;
    }

    return (
      <div className={s.columns}>
        <MainSection className={s.newsColumn}>
          <h1>Foodtruck nieuws</h1>
          {posts.length === 0 && search && <p>Er kunnen geen nieuwsberichten gevonden worden.</p>}
          <div className={s.full}>
            <PagedPostList posts={posts} />
          </div>
          <div className={s.short}>
            <PagedPostList posts={posts} initial={1} perpage={posts.length - 1} />
          </div>
        </MainSection>
        <MainSection className={s.trucksColumn}>
          {false &&
          <LocatorStar className={s.locator} onResolveLocation={this.handleTruckLocatorClick} />}
          <h1><Link to="/foodtrucks">Spot deze trucks</Link></h1>
          {foodtrucks.length === 0 && search && <p>Er kunnen geen trucks gevonden worden.</p>}
          {foodtrucks.length > 0 && search &&
            <MoreButton href={foodtrucksLink}>Zoek verder naar foodtrucks</MoreButton> }
          <div className={s.full}>
            <FoodtruckList foodtrucks={foodtrucks} />
          </div>
          <div className={s.short}>
            <FoodtruckList foodtrucks={foodtrucks.slice(0, 2)} />
          </div>
          {!search && <MoreButton href={foodtrucksLink}>Meer trucks...</MoreButton>}
        </MainSection>
        <MainSection className={s.festivalColumn}>
          <LocatorStar className={s.locator} onResolveLocation={this.handleEventLocatorClick} />
          <h1><Link to="/festivals">Festival agenda</Link></h1>
          {events.length === 0 && !search &&
            <p>Op dit moment zijn er geen festivals gepland ...</p>}
          {events.length === 0 && search && <p>Er kunnen geen festivals gevonden worden.</p>}
          {events.length > 0 && (
            <>
              { search && <MoreButton href={festivalsLink}>Zoek verder naar festivals</MoreButton> }
              <div className={cx(s.festivals, s.full)}>
                <EventList events={events} />
              </div>
              <div className={cx(s.festivals, s.short)}>
                <EventList events={events.slice(0, 2)} />
              </div>
              { !search && <MoreButton href={festivalsLink}>Meer festivals...</MoreButton> }
            </>)}
        </MainSection>
      </div>
    );
  }
}

export default withStyles(s)(Home);
