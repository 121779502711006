import PropTypes from 'prop-types';
import UserShape from './UserShape';
import PostCategoryShape from './PostCategoryShape';

const PostShape = PropTypes.shape({
  id: PropTypes.string,
  slug: PropTypes.string,
  author: UserShape || PropTypes.object,
  authornameoverride: PropTypes.string,
  title: PropTypes.string,
  introduction: PropTypes.string,
  contents: PropTypes.string,
  categories: PropTypes.arrayOf(PostCategoryShape || PropTypes.object),
  images: PropTypes.arrayOf(PropTypes.string),
  createdAt: PropTypes.instanceOf(Date),
  updatedAt: PropTypes.instanceOf(Date),
});
export default PostShape;
