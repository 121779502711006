import React from 'react';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PropTypes from 'prop-types';
import s from './Navigation.css';
import Link from '../Link';
import logo from '../../content/logo.svg';
import UserShape from '../../data/shapes/UserShape';
import PhoneNavigation from './PhoneNavigation';
import SearchField from '../Search/SearchField';
import history from '../../history';

class Navigation extends React.Component {
  static propTypes = {
    user: UserShape,
    hideMenu: PropTypes.bool,
    area: PropTypes.oneOf(['foodtrucks', 'events', 'news', 'search']),
    searchValue: PropTypes.string,
  };

  handleSearch = (searchValue) => {
    history.push(`/?search=${window.encodeURIComponent(searchValue)}`);
  };

  render() {
    const { user, hideMenu, area, searchValue } = this.props;

    return (
      <>
        <PhoneNavigation user={user} />
        <div className={s.navbarcontainer}>
          <div className={s.navbar}>
            <div className={s.loginColumn}>
              {user && (
                <div>
                  <Link to="/profiel">Profiel</Link>
                  <span> of </span>
                  <a href="/logout">Uitloggen</a>
                </div>)}
              {!user && (
                <div>
                  <Link to="/login">Inloggen</Link>
                  <span> of </span>
                  <Link to="/aanmelden">Aanmelden</Link>
                </div>)}
            </div>
            <div className={s.searchColumn}>
              <SearchField
                className={s.searchField}
                onSubmit={this.handleSearch}
                initialValue={searchValue}
              />
            </div>
            <div className={s.logoColumn}>
              <nav className={s.menu}>
                <div className={s.menuLogo}>
                  <Link to="/"><img className={s.logo} alt="FoodtruckSpotters" title="FoodtruckSpotters" src={logo} width="100" height="100" /></Link>
                  <Link to="/" className={s.logoText}>Foodtruck<br />Spotters</Link>
                </div>
                { hideMenu || area === 'foodtrucks' || <Link to="/foodtrucks" className={cx(s.item, s.menuFoodtrucks)}>Spot deze trucks</Link>}
                { hideMenu || area === 'events' || <Link to="/festivals" className={cx(s.item, s.menuEvents)}>Festival agenda</Link>}
                { hideMenu || area === 'news' || <Link to="/" className={cx(s.item, s.menuNews)}>Foodtruck Nieuws</Link>}
              </nav>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(s)(Navigation);
