exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".vvxD4 {\n  display: block;\n  height: 100%;\n  width: 100%;\n  left: 0;\n  top: 0;\n  position: absolute;\n}\n\n._2jEHd {\n  display: block;\n  height: 100%;\n  width: 100%;\n}\n\n.vvxD4::after {\n  content: '';\n  height: 100%;\n  width: 100%;\n  top: 0;\n  left: 0;\n  position: absolute;\n  display: block;\n  background: #000;\n  opacity: 0.3;\n  pointer-events: none;\n  will-change: opacity;\n  -webkit-transition: opacity ease-out 0.2s;\n  -o-transition: opacity ease-out 0.2s;\n  transition: opacity ease-out 0.2s;\n}\n\n._pl0K {\n  display: block;\n  width: 100%;\n  height: 0;\n  padding: 0 0 100% 0;\n  overflow: hidden;\n  position: relative;\n}\n\n._pl0K:hover .vvxD4::after {\n  opacity: 0.1;\n}\n\n._3j3Wo,\n._3f-XD {\n  position: absolute;\n  width: 96%;\n  left: 2%;\n  text-align: center;\n  pointer-events: none;\n  color: #fff;\n}\n\n._3j3Wo {\n  text-transform: uppercase;\n  font-size: 1.1em;\n\n  /* For vertical alignment */\n  top: 50%;\n  -webkit-transform: translateY(-50%);\n      -ms-transform: translateY(-50%);\n          transform: translateY(-50%);\n}\n\n._3f-XD {\n  top: 80%;\n}\n", ""]);

// Exports
exports.locals = {
	"imageLink": "vvxD4",
	"image": "_2jEHd",
	"item": "_pl0K",
	"titleLink": "_3j3Wo",
	"categories": "_3f-XD"
};