import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './MainSection.css';

class MainSection extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  };

  render() {
    const {
      className,
      children,
    } = this.props;

    return (
      <section className={cx(s.mainsection, className)}>{children}</section>
    );
  }
}

export default withStyles(s)(MainSection);

