import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import s from './EventList.css';
import EventShape from '../../data/shapes/EventShape';
import EventListItem from './EventListItem';

class EventList extends React.Component {
  static propTypes = {
    events: PropTypes.arrayOf(EventShape).isRequired,
    responsive: PropTypes.bool,
    scrollPosition: PropTypes.exact({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
  };

  render() {
    const { events, responsive, scrollPosition } = this.props;

    if (!events || events.length === 0) {
      return null;
    }

    return (
      <ul className={cx(s.list, responsive ? s.responsive : false)}>
        {events.map(event => (
          <li key={event.slug} className={s.item}>
            <EventListItem event={event} scrollPosition={scrollPosition} />
          </li>
        )) }
      </ul>
    );
  }
}

export default withStyles(s)(trackWindowScroll(EventList));
