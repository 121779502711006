import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './MoreButton.css';
import Link from '../Link';

class MoreButton extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    href: PropTypes.string,
    htmlFor: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    center: PropTypes.bool,
    small: PropTypes.bool,
    active: PropTypes.bool,
  };

  static defaultProps = {
    center: true,
    type: 'button',
  };

  render() {
    const {
      children,
      onClick,
      href,
      className,
      type,
      disabled,
      center,
      htmlFor,
      small,
      active,
    } = this.props;

    let button = null;
    const buttonClassName = cx(
      s.button,
      !center ? className : false,
      small ? s.small : false,
      active ? s.active : false,
    );

    if (href) {
      const extraProps = {
        target: this.props.target,
        rel: this.props.rel,
      };

      button = <Link to={href} className={buttonClassName} {...extraProps}>{children}</Link>;
    } else if (htmlFor) {
      // eslint-disable-next-line jsx-a11y/label-has-for
      button = <label htmlFor={htmlFor} className={buttonClassName}>{children}</label>;
    } else {
      button = <button type={type} onClick={onClick} className={buttonClassName} disabled={disabled}>{children}</button>;
    }

    if (center) {
      return <div className={cx(s.container, className)}>{button}</div>;
    }

    return button;
  }
}

export default withStyles(s)(MoreButton);
