import React from 'react';
import PropTypes from 'prop-types';
import PostList from './PostList';
import PostShape from '../../data/shapes/PostShape';
import graphqlclient from '../../core/graphqlclient';
import MoreButton from '../Button/MoreButton';

class PagedPostList extends React.Component {
  static propTypes = {
    posts: PropTypes.arrayOf(PostShape).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    initial: PropTypes.number,
    // eslint-disable-next-line react/no-unused-prop-types
    perpage: PropTypes.number,
  };

  static defaultProps = {
    initial: 0,
    perpage: 0,
  };

  static stateFromProps(props) {
    return {
      posts: props.posts,
      perpage: props.perpage || props.posts.length,
      display: props.initial || props.posts.length,
      hasmore: true,
    };
  }

  state = PagedPostList.stateFromProps(this.props);

  componentWillReceiveProps(newProps) {
    if (newProps.posts !== this.props.posts) {
      this.setState(state => ({
        ...state,
        ...PagedPostList.stateFromProps(newProps),
      }));
    }
  }

  handleClick = (e) => {
    e.preventDefault();

    const {
      perpage,
      display,
      posts,
    } = this.state;
    const self = this;

    const wantToDisplay = display + perpage;
    const needToRetrieve = wantToDisplay - posts.length;

    if (needToRetrieve <= 0) {
      self.setState(state => ({
        ...state,
        display: wantToDisplay,
      }));
      return;
    }

    graphqlclient(`query PagePostList($offset: Int, $limit: Int) {
      post(limit: $limit, offset: $offset) {
        id, slug, title, introduction, author { id, slug, displayname, profileimage { id, url } }, authornameoverride, categories { name }, images(limit: 1), createdAt
      }
    }`, { offset: posts.length, limit: needToRetrieve })
      .then((result) => {
        self.setState(state => ({
          ...state,
          posts: [...state.posts, ...result.post],
          display: state.posts.length + result.post.length,
          hasmore: result.post.length === needToRetrieve, // dit is niet supernauwkeurig...
        }));
      })
      // eslint-disable-next-line no-console
      .catch(ex => console.error('Error while fetching next news items', ex));
  };

  render() {
    const { posts, hasmore, display } = this.state;

    const postsToDisplay = posts.slice(0, display);

    return (
      <div>
        <PostList posts={postsToDisplay} />
        { hasmore && <MoreButton onClick={this.handleClick}>Meer nieuws...</MoreButton> }
      </div>);
  }
}

export default PagedPostList;
