export function isOwnImage(url) {
  // Config is niet mogelijk, dit is ook client side.
  return url.indexOf('https://i.foodtruckspotters.nl/') === 0;
}

export function getImageWithSize(attachmentOrUrl, width, height, max) {
  if (!attachmentOrUrl) {
    return attachmentOrUrl;
  }

  const url = attachmentOrUrl.url || attachmentOrUrl;
  if (!url || !isOwnImage(url)) {
    return url;
  }
  const lastDotIndex = url.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return url;
  }
  const type = max && height && width ? '-m' : '';

  return `${url.substr(0, lastDotIndex)}-${width || ''}x${height || ''}${type}${url.substr(lastDotIndex)}`;
}

const amazonRegex = () => /https:\/\/s3-eu-west-1\.amazonaws\.com\/foodtruckspotters\//g;

export function fixImageUrlsInText(text) {
  return text.replace(amazonRegex(), 'https://i.foodtruckspotters.nl/');
}

const pngJpgRegex = () => /\.(jpg|png)$/i;
const hasExactSize = () => /-(\d+)x(\d+)(-m)?\./;

export function isResizable(url) {
  if (!isOwnImage(url)) {
    return false;
  }
  if (!pngJpgRegex().test(url)) {
    return false;
  }
  return !hasExactSize().test(url);
}

export default getImageWithSize;

export function getImageTitle(image) {
  if (image.copyright && image.title) {
    return `${image.title} © ${image.copyright}`;
  }
  if (image.copyright) {
    return `© ${image.copyright}`;
  }
  return image.title;
}

export function createImageInput(value) {
  if (!value) {
    return null;
  }

  return {
    id: value.id,
    name: value.name,
    title: value.title,
    copyright: value.copyright,
    filedata: value.url.substr(0, 5) === 'data:' ? value.url : null,
  };
}
