import PropTypes from 'prop-types';
import AttachmentShape from './AttachmentShape';
import UserLoginShape from './UserLoginShape';

const UserShape = PropTypes.shape({
  id: PropTypes.string,
  email: PropTypes.string,
  newemail: PropTypes.string,
  haspassword: PropTypes.bool,
  slug: PropTypes.string,
  displayname: PropTypes.string,
  profileimage: AttachmentShape || PropTypes.object,
  logins: PropTypes.arrayOf(UserLoginShape || PropTypes.object),
  newsletter: PropTypes.bool,
  canreview: PropTypes.bool,
  token: PropTypes.string,
});
export default UserShape;
