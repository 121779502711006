import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { PostShape } from '../../data/shapes';
import s from './PostList.css';
import PostListItem from './PostListItem';

class PostList extends React.Component {
  static propTypes = {
    posts: PropTypes.arrayOf(PostShape).isRequired,
  };

  render() {
    const { posts } = this.props;
    if (posts.length === 0) {
      return null;
    }

    return (
      <div className={s.list}>
        { posts.map(p => <PostListItem post={p} key={p.slug} />) }
      </div>
    );
  }
}

export default withStyles(s)(PostList);
