import PropTypes from 'prop-types';

const FoodtruckCategoryShape = PropTypes.shape({
  id: PropTypes.string,
  slug: PropTypes.string,
  type: PropTypes.oneOf(['cuisine', 'diet', 'dish', 'drink', 'ingredient', 'preparation', 'product', 'other']),
  name: PropTypes.string,
  numberOfFoodtrucks: PropTypes.number,
});
export default FoodtruckCategoryShape;
