import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { formatDate } from '../../core/dateUtils';
import { PostShape } from '../../data/shapes';
import { getImageWithSize } from '../../core/images';
import s from './PostList.css';
import Link from '../Link';
import MultilineText from '../Utility/MultilineText';

class PostListItem extends React.Component {
  static propTypes = {
    post: PostShape.isRequired,
  };

  render() {
    const { post } = this.props;
    const { author } = post;
    const url = `/blog/${post.slug}`;
    return (
      <article className={s.item}>
        <header className={s.header}>
          <Link to={url}>
            <img className={s.image} src={getImageWithSize(post.images[0], 600, 338)} width="600" height="338" title={`${post.title} - FoodtruckSpotters.nl`} alt={`${post.title} - FoodtruckSpotters.nl`} />
          </Link>
          { !post.authornameoverride && author && author.profileimage &&
            <img className={s.authorImage} src={getImageWithSize(author.profileimage, 60, 60)} width="60" height="60" title={author.displayname} alt={author.displayname} />
          }
          <h2 className={s.title}><Link to={url} className={s.link}>{post.title}</Link></h2>
          <p className={s.byline}>
            {`${post.authornameoverride || (author && author.displayname) || ''} • ${formatDate(post.createdAt)}${post.categories.map(cat => ` • ${cat.name}`).join('')}`}
          </p>
        </header>
        <div className={s.contents}>
          <MultilineText text={post.introduction} />
        </div>
      </article>
    );
  }
}

export default withStyles(s)(PostListItem);
