/* eslint-disable react/no-array-index-key,react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Lightbox from 'react-images';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { getImageWithSize } from '../../core/images';
import s from './MultilineText.css';

const markdownSplit = () => new RegExp('(<figure class="mdgallery">.*</figure>)', 'g');
const galleryMatcher = () => new RegExp('<img src="([^"]*)" alt="([^"]*)" class="mdimage" title="([^"]*)">', 'g');

class MultilineText extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    allowHtml: PropTypes.bool,
    className: PropTypes.string,
    showGalleries: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      currentImage: 0,
      lightboxIsOpen: false,
    };

    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
  }

  openLightbox(index, e) {
    e.preventDefault();
    this.setState(state => ({
      ...state,
      currentImage: index,
      lightboxIsOpen: true,
    }));
  }

  gotoPrevious() {
    this.setState(state => ({
      ...state,
      currentImage: state.currentImage - 1,
    }));
  }

  gotoNext() {
    this.setState(state => ({
      ...state,
      currentImage: state.currentImage + 1,
    }));
  }

  closeLightbox() {
    this.setState(state => ({
      ...state,
      currentImage: 0,
      lightboxIsOpen: false,
    }));
  }

  parseHtmlPart(html, index, allImages) {
    const galleryMatcherInstance = galleryMatcher();
    let galleryMatch = galleryMatcherInstance.exec(html);

    if (galleryMatch) {
      const images = [];
      const indexOffset = allImages.length;
      while (galleryMatch) {
        const image = {
          src: getImageWithSize(galleryMatch[1], 480, 270),
          alt: galleryMatch[2],
          caption: galleryMatch[3],
          width: 480,
          height: 270,
          srcset: [
            `${getImageWithSize(galleryMatch[1], 1920, 1200, true)} 1920w`,
            `${getImageWithSize(galleryMatch[1], 1024, 768, true)} 1024w`,
            `${getImageWithSize(galleryMatch[1], 480, 270, true)} 480w`,
          ],
        };
        images.push(image);
        allImages.push(image);
        galleryMatch = galleryMatcherInstance.exec(html);
      }
      return (
        <div key={index} className={s.gallery}>
          {images.map((image, i) => (
            <div key={i} className={s.imagewrapper}>
              <a href={image.src} onClick={e => this.openLightbox(indexOffset + i, e)}>
                <img
                  src={image.src}
                  alt={image.alt}
                  title={image.alt}
                  className={s.image}
                />
              </a>
            </div>
          ))}
        </div>);
    }

    return <div key={index} dangerouslySetInnerHTML={{ __html: html }} />;
  }

  parseHtml(html, showGalleries) {
    if (!showGalleries) {
      return <div dangerouslySetInnerHTML={{ __html: html }} />;
    }

    const parts = html.split(markdownSplit());
    const allImages = [];
    const components = parts.map((content, index) =>
      this.parseHtmlPart(content, index, allImages));

    if (allImages.length > 0) {
      const { lightboxIsOpen, currentImage } = this.state;
      components.push(<Lightbox
        key={components.length}
        images={allImages}
        backdropClosesModal
        isOpen={lightboxIsOpen}
        currentImage={currentImage}
        onClose={this.closeLightbox}
        onClickPrev={this.gotoPrevious}
        onClickNext={this.gotoNext}
        width={1920}
        imageCountSeparator=" / "
      />);
    }

    return components;
  }

  render() {
    const { text, allowHtml, className, showGalleries } = this.props;
    if (!text) {
      return null;
    }
    if (typeof text !== 'string') {
      return text;
    }

    if (allowHtml) {
      const children = this.parseHtml(text, showGalleries);
      return <div className={cx(s.text, className)}>{children}</div>;
    }

    return <div className={cx(s.text, className)}>{text.trim().replace(/\r/g, '').split('\n').map((line, index) => (<React.Fragment key={index}>{line}<br/></React.Fragment>))}</div>;
  }
}

export default withStyles(s)(MultilineText);
