import queryString from 'query-string';
import history from '../../history';

export const defaultSort = 'name';

export const sorts = {
  name: {
    title: 'Naam',
    field: 'name',
    direction: 'asc',
  },
  rating: {
    title: 'Beste',
    field: 'ratingtotal',
    direction: 'desc',
  },
  new: {
    title: 'Nieuwste',
    field: 'id',
    direction: 'desc',
  },
};

export function gotoUrl({
  search,
  sort,
  longitude,
  latitude,
  categories,
} = {}) {
  let newUrl = '/foodtrucks';

  if (categories) {
    categories.sort();
    categories.forEach((category) => {
      newUrl += `/${category}`;
    });
  }

  const query = {};

  if (search) {
    query.search = search;
  }
  if (sort && defaultSort !== sort) {
    query.sort = sort;
  }
  if (latitude && longitude) {
    delete query.sort;
    query.lat = (`${latitude}`).substr(0, 6);
    query.lon = (`${longitude}`).substr(0, 6);
  }

  newUrl = `${newUrl}?${queryString.stringify(query)}`.replace(/\?$/, '');

  history.push(newUrl);
}
