const resolve = result => ({ then: () => result });

// eslint-disable-next-line import/prefer-default-export
export function isGeolocationAvailable() {
  if (!process.env.BROWSER) {
    return resolve(false);
  }
  if (!('geolocation' in navigator)) {
    return resolve(false);
  }

  if ('permissions' in navigator) {
    return navigator.permissions.query({ name: 'geolocation' })
      .then(result => result.state !== 'denied');
  }

  return resolve(true);
}
