import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Star.css';

class LocatorStar extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    icon: PropTypes.element,
  };

  static defaultProps = {
    className: null,
    onClick: null,
    icon: null,
  };

  render() {
    const {
      onClick,
      className,
      children,
      icon,
    } = this.props;

    const containerProps = {
      className: cx(s.container, className),
    };

    if (onClick) {
      containerProps.onClick = onClick;
    }

    return (
      <div {...containerProps}>
        <div className={s.block}>
          <div className={s.block}>
            <div className={s.block}>
              <div className={s.block}>
                <div className={s.block}>
                  <div className={s.block}>
                    <div className={s.content}>
                      {icon && (
                        <>
                          <div className={s.icon}>{icon}</div>
                          <div className={s.textWithIcon}>
                            {children}
                          </div>
                        </>)}
                      {!icon && children}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(LocatorStar);
